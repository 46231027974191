export default {
  "common": {
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutați ceva care vă place..."])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina nu a fost găsită"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutați ceva"])}
  },
  "footer": {
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting SAGL"])},
    "company_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Vedeggio 4 - 6928 Manno (Switzerland) - Partita IVA CHE- 309.820.548 IVA"])},
    "company_payoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferă întotdeauna comparații fiabile de produse"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate drepturile rezervate"])},
    "cookiePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica privind cookie-urile"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica de confidențialitate"])},
    "cookieSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări cookie"])},
    "for_advertisers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Advertisers"])}
  },
  "search": {
    "sponsored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conținut sponsorizat"])},
    "related_searches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutări sugerate"])},
    "ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AD"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du-te"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezultate pentru:"])},
    "results_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rezultate găsite"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["livrare"])},
    "freeshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livrare gratuită"])},
    "go_to_shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mergeți la magazin"])},
    "try_searching_something": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încercați să căutați ceva"])},
    "no_element_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu au fost găsite elemente"])},
    "searchLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se încarcă..."])},
    "something_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceva a mers prost"])},
    "sort": {
      "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortează după:"])},
      "relevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevanță"])},
      "priceAscending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prețul în creștere"])},
      "priceDescending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prețul în scădere"])}
    }
  },
  "cookie": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptați tot"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respingeți tot"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica de confidențialitate"])},
    "bannerText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Acest site folosește cookie-uri pentru a permite site-ului să funcționeze și să monitorizeze interacțiunile dumneavoastră cu site-ul. Pentru a ne permite să vă oferim cea mai bună experiență posibilă de utilizator, acceptați toate cookie-urile. Pentru a afla mai multe despre datele personale pe care le colectăm și cum sunt utilizate cookie-urile, verificați ", _interpolate(_named("privacyPolicy")), "."])}
  },
  "news": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
  },
  "advertisers": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Advertisers"])},
    "lastUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last Updated: ", _interpolate(_named("last_updated_date"))])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting"])},
    "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traffic to this site is generated through ", _interpolate(_named("company_name")), "'s proprietary technology, which allows us to place native ads with targeted keywords on multiple platforms such as Outbrain, Taboola, and others, which then lead to our various sites where search ads are served."])},
    "additionalInquiries": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For any additional inquiries,\nEmail: ", _interpolate(_named("company_email"))])}
  },
  "newsletter": {
    "infoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buletin informativ"])},
    "formTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonează-te la buletinul informativ"])},
    "formSendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimite"])},
    "formSendingButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se trimite..."])},
    "formSendAnotherButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimite altul"])},
    "formError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceva a mers greșit"])},
    "formSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cererea ta a fost trimisă!"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenume"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume de familie"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresă de Email"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj"])},
      "error": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câmp invalid"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câmp obligatoriu"])},
        "emailInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți o adresă de email validă"])}
      }
    }
  }
}