export default {
  "common": {
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukaj coś, co chcesz..."])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono strony"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukaj coś"])}
  },
  "footer": {
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting SAGL"])},
    "company_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Vedeggio 4 - 6928 Manno (Switzerland) - Partita IVA CHE- 309.820.548 IVA"])},
    "company_payoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapewnienie rzetelnego porównania produktów od zawsze"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszelkie prawa zastrzeżone"])},
    "cookiePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polityka plików cookies"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polityka prywatności"])},
    "cookieSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia plików cookie"])},
    "for_advertisers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Advertisers"])}
  },
  "search": {
    "sponsored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treści sponsorowane"])},
    "related_searches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sugerowane wyszukiwania"])},
    "ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AD"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udać się"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyniki dla:"])},
    "results_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["znaleziono wyniki"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wysyłka"])},
    "freeshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darmowa wysyłka"])},
    "go_to_shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idź do sklepu"])},
    "try_searching_something": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spróbuj coś wyszukać"])},
    "no_element_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono elementów"])},
    "searchLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ładowanie..."])},
    "something_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coś poszło nie tak"])},
    "sort": {
      "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortuj według:"])},
      "relevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istotne"])},
      "priceAscending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena rosnąco"])},
      "priceDescending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena malejąca"])}
    }
  },
  "cookie": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptuj wszystko"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrzuć wszystko"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polityka prywatności"])},
    "bannerText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ta strona korzysta z plików cookie, które pomagają stronie działać, a także śledzić sposób interakcji z naszą witryną. Abyśmy mogli zapewnić jak najlepszą obsługę, prosimy o zaakceptowanie wszystkich plików cookie. Aby dowiedzieć się więcej o danych osobowych, które zbierać i w jaki sposób wykorzystujemy pliki cookie, zapoznaj się z naszą ", _interpolate(_named("privacyPolicy")), "."])}
  },
  "news": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
  },
  "advertisers": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Advertisers"])},
    "lastUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last Updated: ", _interpolate(_named("last_updated_date"))])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting"])},
    "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traffic to this site is generated through ", _interpolate(_named("company_name")), "'s proprietary technology, which allows us to place native ads with targeted keywords on multiple platforms such as Outbrain, Taboola, and others, which then lead to our various sites where search ads are served."])},
    "additionalInquiries": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For any additional inquiries,\nEmail: ", _interpolate(_named("company_email"))])}
  },
  "newsletter": {
    "infoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsletter"])},
    "formTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz się do newslettera"])},
    "formSendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij"])},
    "formSendingButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysyłanie..."])},
    "formSendAnotherButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij kolejny"])},
    "formError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coś poszło nie tak"])},
    "formSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje zgłoszenie zostało wysłane!"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwisko"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres e-mail"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiadomość"])},
      "error": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowe pole"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole wymagane"])},
        "emailInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź prawidłowy adres e-mail"])}
      }
    }
  }
}