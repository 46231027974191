export default {
  "common": {
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procure algo que você goste..."])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página não encontrada"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontre algo"])}
  },
  "footer": {
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting SAGL"])},
    "company_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Vedeggio 4 - 6928 Manno (Switzerland) - Partita IVA CHE- 309.820.548 IVA"])},
    "company_payoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sempre ofereça comparações confiáveis ​​de produtos"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os direitos reservados"])},
    "cookiePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Cookies"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Privacidade"])},
    "cookieSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações de cookies"])},
    "for_advertisers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Advertisers"])}
  },
  "search": {
    "sponsored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conteúdo patrocinado"])},
    "related_searches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisas sugeridas"])},
    "ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AD"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados para:"])},
    "results_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resultados encontrados"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transporte"])},
    "freeshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frete grátis"])},
    "go_to_shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vá para a loja"])},
    "try_searching_something": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tente procurar por algo"])},
    "no_element_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum elemento encontrado"])},
    "searchLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregando..."])},
    "something_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo deu errado"])},
    "sort": {
      "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar por:"])},
      "relevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevância"])},
      "priceAscending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço Ascendente"])},
      "priceDescending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço Descendente"])}
    }
  },
  "cookie": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitar tudo"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negar tudo"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Privacidade"])},
    "bannerText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Este site usa cookies para permitir que o site funcione e para monitorar suas interações com o site. Para nos permitir fornecer a melhor experiência de usuário possível, aceite todos os cookies. Para obter mais informações sobre os dados pessoais que coletar e como os cookies são usados, consulte nossa ", _interpolate(_named("privacyPolicy")), "."])}
  },
  "news": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
  },
  "advertisers": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Advertisers"])},
    "lastUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last Updated: ", _interpolate(_named("last_updated_date"))])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting"])},
    "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traffic to this site is generated through ", _interpolate(_named("company_name")), "'s proprietary technology, which allows us to place native ads with targeted keywords on multiple platforms such as Outbrain, Taboola, and others, which then lead to our various sites where search ads are served."])},
    "additionalInquiries": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For any additional inquiries,\nEmail: ", _interpolate(_named("company_email"))])}
  },
  "newsletter": {
    "infoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsletter"])},
    "formTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscreva a newsletter"])},
    "formSendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "formSendingButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A enviar..."])},
    "formSendAnotherButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar outro"])},
    "formError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo correu mal"])},
    "formSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O seu pedido foi enviado!"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apelido"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de Email"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensagem"])},
      "error": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo inválido"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obrigatório"])},
        "emailInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduza um endereço de email válido"])}
      }
    }
  }
}