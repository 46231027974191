export default {
  "common": {
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchez quelque chose que vous aimeriez..."])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page non trouvée"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cehercher quelque chose"])}
  },
  "footer": {
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting SAGL"])},
    "company_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Vedeggio 4 - 6928 Manno (Switzerland) - Partita IVA CHE- 309.820.548 IVA"])},
    "company_payoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournir une comparaison de produits fiable depuis toujours"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous droits réservés"])},
    "cookiePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de Cookies"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de Confidentialité"])},
    "cookieSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres des cookies"])},
    "for_advertisers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les annonceurs"])}
  },
  "search": {
    "sponsored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annonces liées à"])},
    "related_searches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherches suggérées"])},
    "ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AD"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats pour:"])},
    "results_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["résultats trouvés"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["livraison"])},
    "freeshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison gratuite"])},
    "go_to_shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à la boutique"])},
    "try_searching_something": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayez de rechercher quelque chose"])},
    "no_element_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun élément trouvé"])},
    "searchLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement..."])},
    "something_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose s'est mal passé"])},
    "sort": {
      "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par:"])},
      "relevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevant"])},
      "priceAscending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix croissant"])},
      "priceDescending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix décroissant"])}
    }
  },
  "cookie": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepte tout"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejete tout"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de Confidentialité"])},
    "bannerText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ce site Web utilise des cookies qui aident le site Web à fonctionner et également à suivre la façon dont vous interagissez avec notre site Web. Afin que nous puissions offrir la meilleure expérience utilisateur, veuillez accepter tous les cookies. Pour en savoir plus sur les données personnelles que nous collectons et sur la manière dont nous utilisons les cookies, veuillez consulter notre ", _interpolate(_named("privacyPolicy")), "."])}
  },
  "news": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])}
  },
  "advertisers": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les annonceurs"])},
    "lastUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dernière mise à jour : ", _interpolate(_named("last_updated_date"))])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting"])},
    "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le trafic vers ce site est généré grâce à la technologie propriétaire de ", _interpolate(_named("company_name")), ", qui nous permet de placer des annonces natives avec des mots-clés ciblés sur plusieurs plateformes telles qu'Outbrain, Taboola et d'autres, ce qui mène ensuite à nos différents sites où des annonces de recherche sont diffusées."])},
    "additionalInquiries": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pour toute demande supplémentaire,\nEmail : ", _interpolate(_named("company_email"))])}
  },
  "newsletter": {
    "infoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulletin"])},
    "formTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrivez-vous à la newsletter"])},
    "formSendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "formSendingButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoi en cours..."])},
    "formSendAnotherButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un autre"])},
    "formError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose a mal tourné"])},
    "formSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande a été envoyée !"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse E-mail"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "error": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ non valide"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ obligatoire"])},
        "emailInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer une adresse e-mail valide"])}
      }
    }
  }
}