export default {
  "common": {
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen Sie nach etwas, das Ihnen gefällt..."])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite nicht gefunden"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche etwas"])}
  },
  "footer": {
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting SAGL"])},
    "company_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Vedeggio 4 - 6928 Manno (Switzerland) - Partita IVA CHE- 309.820.548 IVA"])},
    "company_payoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seit jeher zuverlässiger Produktvergleich"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Rechte vorbehalten"])},
    "cookiePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie-Richtlinie"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz-Bestimmungen"])},
    "cookieSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie-Einstellungen"])},
    "for_advertisers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für Werbetreibende"])}
  },
  "search": {
    "sponsored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigen im Zusammenhang mit"])},
    "related_searches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchvorschläge"])},
    "ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AD"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehen"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnisse für:"])},
    "results_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnisse gefunden"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versand"])},
    "freeshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenloser Versand"])},
    "go_to_shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Laden gehen"])},
    "try_searching_something": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuchen Sie, etwas zu suchen"])},
    "no_element_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Elemente gefunden"])},
    "searchLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird geladen..."])},
    "something_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schief gelaufen"])},
    "sort": {
      "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortiere nach:"])},
      "relevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevant"])},
      "priceAscending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis aufsteigend"])},
      "priceDescending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis absteigend"])}
    }
  },
  "cookie": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leugnen"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz-Bestimmungen"])},
    "bannerText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Diese Website verwendet Cookies, die das Funktionieren der Website unterstützen und auch verfolgen, wie Sie mit unserer Website interagieren. Damit wir Ihnen die bestmögliche Benutzererfahrung bieten können, akzeptieren Sie bitte alle Cookies. Um mehr über personenbezogene Daten zu erfahren, wir sammeln und wie wir Cookies verwenden, lesen Sie bitte unsere ", _interpolate(_named("privacyPolicy")), "."])}
  },
  "news": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])}
  },
  "advertisers": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für Werbetreibende"])},
    "lastUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Letzte Aktualisierung: ", _interpolate(_named("last_updated_date"))])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting"])},
    "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der Traffic auf dieser Seite wird durch die proprietäre Technologie von ", _interpolate(_named("company_name")), " generiert, die es uns ermöglicht, native Anzeigen mit gezielten Schlüsselwörtern auf mehreren Plattformen wie Outbrain, Taboola und anderen zu platzieren, die dann zu unseren verschiedenen Seiten führen, auf denen Suchanzeigen geschaltet werden."])},
    "additionalInquiries": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Für weitere Anfragen,\nE-Mail: ", _interpolate(_named("company_email"))])}
  },
  "newsletter": {
    "infoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsletter"])},
    "formTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melden Sie sich für den Newsletter an"])},
    "formSendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
    "formSendingButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden..."])},
    "formSendAnotherButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nochmals senden"])},
    "formError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schiefgelaufen"])},
    "formSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Anfrage wurde gesendet!"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht"])},
      "error": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Feld"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflichtfeld"])},
        "emailInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine gültige E-Mail-Adresse eingeben"])}
      }
    }
  }
}