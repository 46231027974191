export default {
  "common": {
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search something you'd like..."])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search something"])}
  },
  "footer": {
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting SAGL"])},
    "company_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Vedeggio 4 - 6928 Manno (Switzerland) - Partita IVA CHE- 309.820.548 IVA"])},
    "company_payoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Providing reliable product comparison since always"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All right reserved"])},
    "cookiePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie Policy"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "cookieSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie Settings"])},
    "for_advertisers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Advertisers"])}
  },
  "search": {
    "sponsored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsored content"])},
    "related_searches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggested searches"])},
    "ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AD"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results for:"])},
    "results_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results found"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shipping"])},
    "freeshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free shipping"])},
    "go_to_shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to shop"])},
    "try_searching_something": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try searching something"])},
    "no_element_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No elements found"])},
    "searchLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "something_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
    "sort": {
      "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by:"])},
      "relevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevant"])},
      "priceAscending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price ascending"])},
      "priceDescending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price descending"])}
    }
  },
  "cookie": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept all"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deny all"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "bannerText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This website uses cookies that help the website to function and also to track how you interact with our website. In order for us to provide the best user experience, please accept all cookies. To find out more about personal data we collect and how we use cookies please refer to our ", _interpolate(_named("privacyPolicy")), "."])}
  },
  "news": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
  },
  "advertisers": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Advertisers"])},
    "lastUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last Updated: ", _interpolate(_named("last_updated_date"))])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting"])},
    "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traffic to this site is generated through ", _interpolate(_named("company_name")), "'s proprietary technology, which allows us to place native ads with targeted keywords on multiple platforms such as Outbrain, Taboola, and others, which then lead to our various sites where search ads are served."])},
    "additionalInquiries": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For any additional inquiries,\nEmail: ", _interpolate(_named("company_email"))])}
  },
  "newsletter": {
    "infoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsletter"])},
    "formTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe to the newsletter"])},
    "formSendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "formSendingButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending..."])},
    "formSendAnotherButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send another"])},
    "formError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
    "formSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request has been sent!"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "error": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid field"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required field"])},
        "emailInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid email address"])}
      }
    }
  }
}