export default {
  "common": {
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek iets dat je leuk vindt..."])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina niet gevonden"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek iets"])}
  },
  "footer": {
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting SAGL"])},
    "company_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Vedeggio 4 - 6928 Manno (Switzerland) - Partita IVA CHE- 309.820.548 IVA"])},
    "company_payoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrouwbare productvergelijking sinds altijd"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle rechten voorbehouden"])},
    "cookiePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie beleid"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacybeleid"])},
    "cookieSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie Instellingen"])},
    "for_advertisers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Advertisers"])}
  },
  "search": {
    "sponsored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesponsorde inhoud"])},
    "related_searches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwante zoekopdrachten"])},
    "ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AD"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaan"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultaten voor:"])},
    "results_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resultaten gevonden"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzending"])},
    "freeshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen verzendkosten"])},
    "go_to_shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar winkel"])},
    "try_searching_something": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer iets te zoeken"])},
    "no_element_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen elementen gevonden"])},
    "searchLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezig met laden..."])},
    "something_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets fout gegaan"])},
    "sort": {
      "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorteer op:"])},
      "relevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevantie"])},
      "priceAscending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs oplopend"])},
      "priceDescending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs aflopend"])}
    }
  },
  "cookie": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepteer"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontkennen"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacybeleid"])},
    "bannerText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deze website maakt gebruik van cookies die de website helpen functioneren en ook om bij te houden hoe u met onze website omgaat. Om de beste gebruikerservaring te kunnen bieden, dient u alle cookies te accepteren. Voor meer informatie over persoonlijke gegevens die wij verzamelen en hoe we cookies gebruiken, raadpleeg ons ", _interpolate(_named("privacybeleid")), "."])}
  },
  "news": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
  },
  "advertisers": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Advertisers"])},
    "lastUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last Updated: ", _interpolate(_named("last_updated_date"))])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting"])},
    "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traffic to this site is generated through ", _interpolate(_named("company_name")), "'s proprietary technology, which allows us to place native ads with targeted keywords on multiple platforms such as Outbrain, Taboola, and others, which then lead to our various sites where search ads are served."])},
    "additionalInquiries": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For any additional inquiries,\nEmail: ", _interpolate(_named("company_email"))])}
  },
  "newsletter": {
    "infoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwsbrief"])},
    "formTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schrijf je in voor de nieuwsbrief"])},
    "formSendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzenden"])},
    "formSendingButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezig met verzenden"])},
    "formSendAnotherButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstuur opnieuw"])},
    "formError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets misgegaan"])},
    "formSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je aanvraag is verzonden!"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht"])},
      "error": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldig veld"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verplicht veld"])},
        "emailInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer een geldig e-mailadres in"])}
      }
    }
  }
}