export default {
  "common": {
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busca algo que te guste..."])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página no encontrada"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar algo"])}
  },
  "footer": {
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting SAGL"])},
    "company_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Vedeggio 4 - 6928 Manno (Switzerland) - Partita IVA CHE- 309.820.548 IVA"])},
    "company_payoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siempre ofrece comparaciones de productos confiables"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los derechos reservados"])},
    "cookiePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Cookies"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Privacidad"])},
    "cookieSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de cookies"])},
    "for_advertisers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Advertisers"])}
  },
  "search": {
    "sponsored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenido patrocinado"])},
    "related_searches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Búsquedas relacionadas"])},
    "ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AD"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vamos"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados para:"])},
    "results_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resultados encontrados"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de envío"])},
    "freeshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envío gratis"])},
    "go_to_shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a la tienda"])},
    "try_searching_something": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intenta buscar algo"])},
    "no_element_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron elementos"])},
    "searchLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando..."])},
    "something_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo salió mal"])},
    "sort": {
      "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar por:"])},
      "relevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevancia"])},
      "priceAscending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio en aumento"])},
      "priceDescending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio decreciente"])}
    }
  },
  "cookie": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar todo"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazar todo"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Privacidad"])},
    "bannerText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Este sitio utiliza cookies para permitir que el sitio funcione y monitorear sus interacciones con el sitio. Para permitirnos brindarle la mejor experiencia de usuario posible, acepte todas las cookies. Para obtener más información sobre los datos personales que recopilamos y cómo se utilizan las cookies consulte nuestra ", _interpolate(_named("privacyPolicy")), "."])}
  },
  "news": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
  },
  "advertisers": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Advertisers"])},
    "lastUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last Updated: ", _interpolate(_named("last_updated_date"))])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting"])},
    "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traffic to this site is generated through ", _interpolate(_named("company_name")), "'s proprietary technology, which allows us to place native ads with targeted keywords on multiple platforms such as Outbrain, Taboola, and others, which then lead to our various sites where search ads are served."])},
    "additionalInquiries": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For any additional inquiries,\nEmail: ", _interpolate(_named("company_email"))])}
  },
  "newsletter": {
    "infoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boletín"])},
    "formTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscríbete al boletín"])},
    "formSendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "formSendingButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviando..."])},
    "formSendAnotherButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar otro"])},
    "formError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo salió mal"])},
    "formSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Tu solicitud ha sido enviada!"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo Electrónico"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje"])},
      "error": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo no válido"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obligatorio"])},
        "emailInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese un correo electrónico válido"])}
      }
    }
  }
}