export default {
  "common": {
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg efter noget, du gerne vil..."])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Side ikke fundet"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg noget"])}
  },
  "footer": {
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting SAGL"])},
    "company_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Vedeggio 4 - 6928 Manno (Switzerland) - Partita IVA CHE- 309.820.548 IVA"])},
    "company_payoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siden altid har vi leveret pålidelige produktsammenligninger"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle rettigheder forbeholdes"])},
    "cookiePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politik om cookies"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privatlivspolitik"])},
    "cookieSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie-indstillinger"])},
    "for_advertisers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Advertisers"])}
  },
  "search": {
    "sponsored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsoreret indhold"])},
    "related_searches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreslåede søgninger"])},
    "ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AD"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultater for:"])},
    "results_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resultater fundet"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forsendelse"])},
    "freeshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratis forsendelse"])},
    "go_to_shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til butik"])},
    "try_searching_something": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv at søge efter noget"])},
    "no_element_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen elementer fundet"])},
    "searchLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indlæsning..."])},
    "something_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noget gik galt"])},
    "sort": {
      "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorter efter"])},
      "relevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevant"])},
      "priceAscending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stigende pris"])},
      "priceDescending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pris faldende"])}
    }
  },
  "cookie": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter alle"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afvise alle"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privatlivspolitik"])},
    "bannerText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dette websted bruger cookies, der hjælper webstedet med at fungere og også til at spore, hvordan du interagerer med vores websted. For at vi kan give dig den bedste brugeroplevelse, skal du acceptere alle cookies. Hvis du vil vide mere om de personlige data, vi indsamler, og hvordan vi bruger cookies, kan du læse mere om vores ", _interpolate(_named("privacyPolicy")), "."])}
  },
  "news": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
  },
  "advertisers": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Advertisers"])},
    "lastUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last Updated: ", _interpolate(_named("last_updated_date"))])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SM Management & Consulting"])},
    "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traffic to this site is generated through ", _interpolate(_named("company_name")), "'s proprietary technology, which allows us to place native ads with targeted keywords on multiple platforms such as Outbrain, Taboola, and others, which then lead to our various sites where search ads are served."])},
    "additionalInquiries": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For any additional inquiries,\nEmail: ", _interpolate(_named("company_email"))])}
  },
  "newsletter": {
    "infoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyhedsbrev"])},
    "formTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilmeld dig nyhedsbrevet"])},
    "formSendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "formSendingButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender..."])},
    "formSendAnotherButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send en anden"])},
    "formError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noget gik galt"])},
    "formSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din anmodning er sendt!"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornavn"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efternavn"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadresse"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besked"])},
      "error": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyldigt felt"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Påkrævet felt"])},
        "emailInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast en gyldig e-mailadresse"])}
      }
    }
  }
}